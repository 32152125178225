export default {
  USER_LOGGED_IN: 'USER_LOGGED_IN',
  USER_LOGGED_OUT: 'USER_LOGGED_OUT',
  THEME_LIGHT: 'light',
  THEME_DARK: 'dark',
  COLOR_PRIMARY: 'primary',
  COLOR_SECONDARY: 'secondary',
  COLOR_SUBMIT: 'submit',
  ACTION_TOGGLE: 'toggle',
  ACTION_SUBMIT: 'submit',
  ACTION_DOWNLOAD: 'download',
  DENOMINATION_CURRENCY: 'currency',
  DENOMINATION_COUNT: 'count',
  TIME_DAYS: 'Days',
  TIME_WEEKS: 'Weeks',
  TIME_MONTHS: 'Months',
  TIME_YEARS: 'Years',
  TIME_ALL: 'All',
};

export const QNS_VALUE = '888888';
export const UNAVAILABLE_VALUE = '999999';
export const DEFAULT_UAT_AUTH_TIMEOUT = 1000 * 60 * 30; // 30 min
export const DEFAULT_PROD_AUTH_TIMEOUT = 1000 * 60 * 15; // 15 min
export const MULTI_UNIT_INPUTS = [
  'MeasureWeight',
  'MeasurePhysical',
  'MeasureLiquid',
  'PhysicalTemp',
];

export const myServerEnv = process.env.REACT_APP_SERVER_ENV ? process.env.REACT_APP_SERVER_ENV.toLowerCase() : 'uat';

export const myProjectName = process.env.REACT_APP_PROJECT_NAME
  ? process.env.REACT_APP_PROJECT_NAME.toLowerCase()
  : 'rb1';
