import React from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Row } from 'reactstrap';

import { DataServices, LangServices } from '@lainaedge/platformshared';
import logo from 'Common/assets/images/logo-laina.png';
// import images
import vector2 from 'Common/assets/images/vector2.png';
import { myProjectName, myServerEnv } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';

import packageJson from '../../../package.json';

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

DataServices.setEnvironment(
  myServerEnv as Environment,
);
DataServices.setProjectName(myProjectName);
DataServices.setServerAddress('');

type Props = {
  children: JSX.Element;
  showForgotPassword?: boolean;
};

const langService = LangServices.instance();
/**
 * StudyNonAuthLayout component.
 *
 * @remarks
 * Layout for guest participants
 *
 * @component StudyNonAuthLayout
 * @category Component
 */
const StudyLayout = ({ children, showForgotPassword = false }: Props): JSX.Element =>
{
  const { versionInfo, dashTitle } = useData();
  const { error, clearState } = useAuth();

  const titleParts = dashTitle.split('(');

  const handleClearState = () =>
  {
    clearState();
  };

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p>{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="pt-4">
                  <div className="p-2">
                    {error && error ? <Alert color="danger">{error}</Alert> : null}
                    {children}
                    {myServerEnv === 'uat' && (
                      <div className="mt-3 login-page-small">
                        This is the User Acceptance Test (UAT) Environment. If you are at the site
                        or looking for production data &nbsp;
                        <a
                          className="login-notice"
                          href={'https://prod.' + myProjectName + '.trial.lainaent.com/'}
                        >
                          Click Here.{' '}
                        </a>
                      </div>
                    )}

                    {myServerEnv === 'prod' && (
                      <div className="mt-3">This is the Production Server for {dashTitle}</div>
                    )}
                  </div>

                  {showForgotPassword && (
                    <div className="mt-4 text-center">
                      <Link
                        to="/study/forgotPassword/1"
                        className="text-muted"
                        onClick={handleClearState}
                      >
                        <i className="fa-solid fa-lock-keyhole"></i> {langService.Translate('Forgot your password')}?
                      </Link>
                    </div>
                  )}
                </CardBody>
              </Card>
              <div className="text-center login-page-small">
                CPS : {versionInfo?.clientPlatformSharedVersion}, SPS :{' '}
                {versionInfo?.serverPlatformSharedVersion}, UI : {packageJson.version}, TDD:{' '}
                {versionInfo?.serverTDDVersion}, V: {versionInfo?.version}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default StudyLayout;
