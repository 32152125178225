import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';

import { DataServices } from '@lainaedge/platformshared';
import { PingResult } from '@lainaedge/platformshared/src/types/PingResult';
import IdleWatch from 'Admin/components/IdleWatch';
import TrialWatch from 'Admin/components/TrialWatch';
import ChangePwd from 'Admin/pages/ChangePassword';
import DefaultPage from 'Admin/pages/DefaultPage';
import MagicPage from 'Admin/pages/MagicPage';
import CreateMagicLink from 'Authentication/pages/CreateMagicLink';
import ForgetPwd from 'Authentication/pages/ForgetPassword';
import HubMagicLoginPage from 'Authentication/pages/HubMagicLoginPage';
import Login from 'Authentication/pages/Login';
import MobileLogin from 'Authentication/pages/MobileLogin';
import { myProjectName, myServerEnv } from 'Common/constants';
import { MenuItem } from 'Common/constants/types';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';
// layouts
import AuthLayout from 'Common/layout/AuthLayout';
import NonAuthLayout from 'Common/layout/NonAuthLayout';
import ParticipantLayout from 'Common/layout/ParticipantLayout';
import { getAppVersion, getEDCPortalConfig } from 'Common/services';
import MainPageRender from 'PageEngine/components/MainPageRender';
import ParticipantEnterPassword from 'Participant/pages/EnterPassword';
import ParticipantForgotPassword from 'Participant/pages/ForgotPassword';
import ParticipantSetPassword from 'Participant/pages/SetPassword';
import ParticipantStudy from 'Participant/pages/Study';

// Import scss
import 'Common/assets/scss/theme.scss';
// Fontawesome
import '@fortawesome/fontawesome-pro/js/all';

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

/** Set up project environment for DataServices */
DataServices.setEnvironment(
  myServerEnv as Environment,
);
DataServices.setProjectName(myProjectName);
DataServices.setServerAddress('');

const guestRoutes = [
  { path: '/login', component: Login },
  { path: '/mobile-login', component: MobileLogin },
  { path: '/forgot-password', component: ForgetPwd },
  { path: '/magic/:code', component: HubMagicLoginPage },
  { path: '/newMagic', component: CreateMagicLink },
  { path: '/study/setPassword/:pid', component: ParticipantSetPassword },
  { path: '/study/enterPassword/:pid', component: ParticipantEnterPassword },
  { path: '/study/forgotPassword/:pid', component: ParticipantForgotPassword },
  { path: '/study', component: ParticipantStudy },
  { path: '/loading', component: DefaultPage },
  // { path: '/system/magicPage', component: MagicPage },
];

const internalRoutes = [
  { path: '/changepassword', component: ChangePwd },
  { path: '/page/:page', component: MainPageRender },
  { path: '/:task/page/:page', component: MainPageRender },
  { path: '/_edit/:task/page/:page', component: MainPageRender },
  { path: '/_edit/page/:page', component: MainPageRender },
  { path: '/_edit/page/:page/:pid/:id', component: MainPageRender },
  { path: '/_editqueryid', component: MainPageRender },
  { path: '*', component: MainPageRender },
  { path: '/trialwatch', component: TrialWatch },
];

const App = () =>
{
  const { isAuthenticated, setDefaultRoute } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  const {
    setVersionInfo,
    setConfig,
    setDashTitle,
    setTitleText,
    setFooterRightText,
    setMenuItems,
    setFooterText,
    setLogo,
    setLogoSm,
  } = useData();

  useEffect(() =>
  {
    async function getData()
    {
      const version: PingResult = await getAppVersion();
      if (version)
      {
        setVersionInfo(version);
      }

      const config = await getEDCPortalConfig();

      if (config)
      {
        let level = 0;
        const menuItems: Array<MenuItem> = [];
        let index = 0;
        let in_submenu = 0;

        // build menuItems
        const Sidebar = config.Sidebar;
        Sidebar.map((item: any) =>
        {
          if (!item.item_type) item.item_type = 'Link';
          if (level === 0 && item.item_type === 'Link')
          {
            in_submenu = 0;
            menuItems.push({
              ...item,
              children: [],
            });
            index++;
          } else if (item.item_type.startsWith('Menu'))
          {
            if (in_submenu === 1) index++;
            in_submenu = 1;

            level = 1;
            menuItems.push({
              ...item,
              children: [],
            });
          } else if (item.item_type === 'Link' && level === 1)
          {
            if (!item.route) item.route = '/page/screenfailed';
            menuItems[index].children.push({
              ...item,
              children: [],
            });
          }
          return item;
        });

        let logo = 'laina-logo.png';
        let logoSm = 'laina-logo-sm.png';
        let title_text = 'LAINA EDC PORTAL';
        let footer_text = new Date().getFullYear() + ' © Laina';
        let footer_text_right = '';
        let dashTitle = '';

        const WebsiteConfig = config.tables.WebsiteConfig;
        const settings = WebsiteConfig[0].data;
        // build Site Config
        if (settings)
        {
          const mainLogo: any = settings.find((item: any) => item.setting === 'Logo-main');
          if (mainLogo) logo = mainLogo.value;

          const smallLogo: any = settings.find((item: any) => item.setting === 'Logo-small');
          if (smallLogo) logoSm = smallLogo.value;

          const faviconObj: any = settings.find((item: any) => item.setting === 'Favicon');
          if (faviconObj)
          {
            const favicon: HTMLElement = document.getElementById('favicon')!;
            favicon.setAttribute('href', '/' + faviconObj.value);
          }

          const title: any = settings.find((item: any) => item.setting === 'Title');
          if (title) title_text = title.value;

          const footer: any = settings.find((item: any) => item.setting === 'Footer_text');
          if (footer) footer_text = footer.value;

          const footer_right: any = settings.find(
            (item: any) => item.setting === 'Footer_text_right',
          );
          if (footer_right) footer_text_right = footer_right.value;

          const dash_title: any = settings.find((item: any) => item.setting === 'DashTitle');
          if (dash_title) dashTitle = dash_title.value;

          const default_route: any = settings.find((item: any) => item.setting === 'default_route');
          setDefaultRoute(default_route ? default_route.value : '/login');
        }

        setConfig(config);
        console.log('menuItems', menuItems);
        setMenuItems(menuItems);
        setLogo(logo);
        setLogoSm(logoSm);
        setTitleText(title_text);
        setFooterText(footer_text);
        setFooterRightText(footer_text_right);
        setDashTitle(dashTitle);
      }

      // const dataDictionary = await getDataDictionary();
    }

    getData();
    // eslint-disable-next-line
  }, []);

  const Dashboard = (): JSX.Element => <Navigate to="/page/portaldashboard" />;

  internalRoutes.push({
    path: '/',
    component: Dashboard,
  });

  return (
    <React.Fragment>
      <IdleWatch />

      <Routes>
        <Route path="/" element={<AuthLayout />}>
          {internalRoutes.map(({ component, path }, idx) =>
          {
            return (
              <Route
                path={path}
                key={idx}
                element={
                  isAuthenticated ? (
                    React.createElement(component, { navigate, location })
                  ) : (
                    <Navigate to={{ pathname: '/loading' }} />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/" element={<NonAuthLayout />}>
          {guestRoutes.map(({ component, path }, idx) =>
          {
            return (
              <Route
                path={path}
                key={idx}
                element={
                  !isAuthenticated ? (
                    React.createElement(component, {})
                  ) : (
                    <Navigate to={{ pathname: '/' }} />
                  )
                }
              />
            );
          })}
        </Route>
        <Route path="/" element={<ParticipantLayout />}>
          <Route
            path={'/system/magicPage'}
            element={
              !isAuthenticated ? (
                React.createElement(MagicPage, {})
              ) : (
                <Navigate to={{ pathname: '/' }} />
              )
            }
          />
        </Route>
      </Routes>
    </React.Fragment>
  );
};

App.displayName = 'App';

export default App;
