import React, { useEffect } from 'react';
import { Cookies } from 'react-cookie';
import { Helmet } from 'react-helmet';
import { Outlet, useLocation } from 'react-router-dom';

import BookmarkModal from 'Common/components/BookmarkModal';
import GearModal from 'Common/components/GearModal';
import { myProjectName } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';
import QuickMenuRender from 'PageEngine/components/QuickMenuRender';

import Footer from './Footer';
// Layout Related Components
import Header from './Header';
import Sidebar from './Sidebar';
import StudyHeader from './StudyHeader';

const cookies = new Cookies();

const Layout = () =>
{
  const location = useLocation();
  const { leftSideBarType, setLeftSideBarType, showQuickMenu, showGearModal, showBookmarkModal, pageTitle, versionInfo, title_text } =
    useData();
  const { userType, isAuthenticated } = useAuth();

  useEffect(() =>
  {
    if (!isAuthenticated && location.pathname !== '/')
    {
      cookies.set('redirectUrl', location.pathname, { path: '/' });
    }
  }, []);

  useEffect(() =>
  {
    document.getElementById('preloader')!.style.display = 'none';
    document.getElementById('status')!.style.display = 'none';
    // Scroll Top to 0
    window.scrollTo(0, 0);

    if (leftSideBarType)
    {
      setLeftSideBarType(leftSideBarType);
    }
  }, [location.pathname, setLeftSideBarType, leftSideBarType]);

  const sanitizedPateTitle = pageTitle + ' | V' + versionInfo?.version + ' | ' + title_text

  return (
    <React.Fragment>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{sanitizedPateTitle}</title>
        <meta name="title" content={sanitizedPateTitle} />
        <meta property="og:title" content={sanitizedPateTitle} />
      </Helmet>
      <div id="preloader">
        <div id="status">
          <div className="spinner-chase">
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
            <div className="chase-dot"></div>
          </div>
        </div>
      </div>

      {userType === 'participant' || userType === 'magic' ? (
        <div id="layout-wrapper">
          {showQuickMenu && myProjectName == 'demo' && <QuickMenuRender />}
          <StudyHeader></StudyHeader>
          <div className="participant-content">
            <Outlet />
          </div>
          <Footer type={'participant'} />
        </div>
      ) : (
        <div id="layout-wrapper">
          {showQuickMenu && <QuickMenuRender />}
          {showGearModal && <GearModal />}
          {showBookmarkModal && <BookmarkModal />}
          <Header />
          <Sidebar />
          <div className="main-content">
            <Outlet />
          </div>
          <Footer />
        </div>
      )}
    </React.Fragment>
  );
};

export default Layout;
