import React from 'react';

import ProfileMenu from 'Common/components/ProfileMenu';
// context
import { useData } from 'Common/context/DataContext';

const StudyHeader = () =>
{
  const { pageTitle } = useData();
  return (
    <React.Fragment>
      <header id="study-topbar" className="participant-header">
        <p>{pageTitle}</p>
        <div className="profile-menu">
          <ProfileMenu />
        </div>
      </header>
    </React.Fragment>
  );
};

export default StudyHeader;
