import React from 'react';

import { useData } from 'Common/context/DataContext';

import SidebarContent from './SidebarContent';

const Sidebar = () =>
{
  const { leftSideBarType, setLeftSideBarType } = useData();

  const handleMouseLeave = () =>
  {
    if (leftSideBarType === 'menu-opened')
    {
      setLeftSideBarType('menu-closed');
    }
  };

  return (
    <React.Fragment>
      <div className="vertical-menu" onMouseLeave={handleMouseLeave}>
        <div data-simplebar className="h-100">
          <SidebarContent />
        </div>
      </div>
    </React.Fragment>
  );
};

export default Sidebar;
