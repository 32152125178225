import React from 'react';

import { StepForm } from '@lainaedge/platformshared';
import classNames from 'classnames';

import FormInput from './FormInput';

const HtmlToReactParser = require('html-to-react').Parser;
const htmlToReactParser = new HtmlToReactParser();

/**
 * Lookup component
 *
 * @component Lookup
 * @category FormElements
 */
export default class Lookup extends FormInput
{
  /**
   * Used to change the value of a radio field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  handleChangeRadio = (field: StepForm.FieldInfo, e: any) =>
  {
    this.setValue(field, e.target.value);
  };

  /**
   * Renders Lookup class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;
    const is_on_modal = this.props.formProps.is_on_modal;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    if (step.tableDef && step.dataDictionary)
    {
      const name = step.tableDef.getFieldLookupName(field.field);

      /** Represents a lookup table */
      const list = step.dataDictionary.getLookupTable(name);

      /** Represents a value of a field as it would be stored in the database. */
      const myValueDatabase = step.getValueDatabase(field.field);

      return (
        <>
          {list
            ? list.map((item: any) => (
              <div className="custom-control custom-radio mb-3" key={item.code}>
                <input
                  type="radio"
                  id={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field + item.code
                      : is_on_modal
                        ? 'm' + field.field + item.code
                        : field.field + item.code
                  }
                  className="custom-control-input"
                  name={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field
                      : this.isEditMode()
                        ? 'm' + field.field
                        : field.field
                  }
                  value={item.code}
                  checked={
                    this.isEditFieldOnModal()
                      ? this.props.formProps.edit_values[field.field] === item.code
                      : this.props.formProps.hide_fields[field.field]
                        ? false
                        : myValueDatabase === item.code
                  }
                  disabled={this.isFieldDisabled()}
                  onChange={(e: any) =>
                  {
                    if (this.isEditFieldOnModal())
                    {
                      this.props.formProps.handleChangeEditValues(field, e.target.value);
                    } else
                    {
                      this.handleChangeRadio(field, e);
                    }
                  }}
                />
                <label
                  className={classNames(
                    'custom-control-label',
                    this.isFieldDisabled() ? 'disabled' : '',
                  )}
                  htmlFor={
                    this.isEditFieldOnModal()
                      ? 'e' + field.field + item.code
                      : is_on_modal
                        ? 'm' + field.field + item.code
                        : field.field + item.code
                  }
                >
                  {htmlToReactParser.parse(item.english)}
                </label>
              </div>
            ))
            : ''}
          {this.renderQnsAndUnavailableSwitches()}
          {this.renderValidationError()}
        </>
      );
    }

    return <></>;
  }
}
