import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { useLocation, useNavigate } from 'react-router-dom';
import { Card, Col, Container,Row } from 'reactstrap';

import
{
  DataServices,
  DataTools,
  LogicEngine,
  PageEngine,
  SystemProblemEventType,
} from '@lainaedge/platformshared';
import { AuthResult } from '@lainaedge/platformshared/src/types/AuthResult';
import { ParticipantAuthResult } from '@lainaedge/platformshared/src/types/ParticipantAuthResult';
import { ValidateParticipantResult } from '@lainaedge/platformshared/src/types/ValidateParticipantResult';
import { myProjectName,myServerEnv } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { RenderHelper } from 'PageEngine/components/RenderHelper';

const cookies = new Cookies();

const dataService = DataServices.instance();

const logic: LogicEngine = new LogicEngine();

const DEMO_PID_PASSWORD = 'laina1234';

type Environment = 'test' | 'dev' | 'uat' | 'stg' | 'prod';

/** Set up project environment for DataServices */
DataServices.setEnvironment(
  myServerEnv as Environment,
);
DataServices.setProjectName(myProjectName);
DataServices.setServerAddress('');


/**
Connect to the central hub and authenticate a particiapnt ID value.
Will output issues found if the PID wasn't able to connect or redirect to a trial.
*/
export async function hubValidate(
  pid: string,
  envName?: Environment,
): Promise<undefined | ValidateParticipantResult>
{
  //  Step 1  - Always set the environment to UAT, Dev, or Prod

  if (envName) DataServices.setEnvironment(envName);
  else DataServices.setEnvironment('uat');

  //  Step 2 - Ping the hub
  const successRespnse = await dataService.validateParticipantHubServer(pid);
  if (!successRespnse)
  {
    console.log('hubValidate: Error 1 in validateParticipantHubServer');
    return undefined;
  }

  if (successRespnse.success)
  {
    if (successRespnse.trialName)
    {
      //  Participant has been found in the central hub.
      DataServices.setProjectName(successRespnse.trialName);
      return successRespnse;
    } else
    {
      console.log(
        'hubValidate: Error 3 in validateParticipantHubServer, participant project not found',
      );
      console.dir(successRespnse);
      return undefined;
    }
  } else
  {
    console.log('hubValidate: Error 2 in validateParticipantHubServer, participant not found');
    console.dir(successRespnse);
    return undefined;
  }
}

/** Demo script helper function that will login a participant to whatever the trial server
is that they should login to.  Sets the password if needed.
*/
export async function participantLogin(
  pid: string,
  setError: (str: string) => void,
  envName?: Environment,
): Promise<undefined | ParticipantAuthResult>
{
  if (!envName || envName != 'prod') envName = 'uat';

  const result = await hubValidate(pid, envName);
  if (result === undefined) return undefined;

  //  We know which trial server to connect to.  Let's see if the password is needed.
  if (result.passwordStatus === 'needed')
  {
    console.log('participantLogin: Password is neeeded:', result);
    const passwordResult = await dataService.participantSetPassword(pid, DEMO_PID_PASSWORD);
    if (!passwordResult || !passwordResult.success)
    {
      console.log('participantLogin: Unable to set new user password');
      console.dir(passwordResult);
      setError('Thank you for your time but you are not allowed to access this page');
      return undefined;
    }
  }

  //  Setup participant session
  // Setup global exception handler for Data Services
  DataServices.evSystemProblem.subscribe((systemException: SystemProblemEventType) =>
  {
    console.log('*** Received System Exception ***');
    console.dir(systemException);
    console.log('*********************************');
  });

  // Authenticate participant
  const authResult: ParticipantAuthResult = await dataService.authParticipant(
    pid,
    DEMO_PID_PASSWORD,
  );
  if (!authResult)
  {
    console.log('participantLogin:  Invalid response');
    return undefined;
  }

  if (!authResult.success)
  {
    console.log('participantLogin Invalid Login:', authResult);
    return undefined;
  }

  console.log('Participant Auth Valid:', authResult);
  return authResult;
}

/**
 * MagicPage component.
 *
 * @remarks
 * MagicPage that parses and runs the command
 *
 * @component MagicPage
 * @category Page
 */
const MagicPage = (): JSX.Element =>
{
  const { clearState, setUser, setUserType, setIsAuthenticated } = useAuth();
  const [error, setError] = useState('');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() =>
  {
    async function doMagic()
    {

      const queryString = new URLSearchParams(location.search).get('code') || '';

      const parameters = DataTools.decodeObject(queryString.replaceAll(' ', '+'));

      if (Object.keys(parameters).length == 0)
      {
        setError('There is error parsing the code');
        return;
      }

      clearState();
      if (parameters.Username && parameters.Password)
      {
        dataService
          .authUser(parameters.Username, parameters.Password, { version: '0.0.39' })
          .then((resp: AuthResult) =>
          {
            if (resp.isValidLogin)
            {
              cookies.set('authUser', resp, { path: '/' });
              cookies.set('token', resp.authToken, { path: '/' });
              cookies.set('type', 'magic', { path: '/' });
              setUserType('magic');
              setIsAuthenticated(true);
              assignConfig(parameters);
              //document.location.href = parameters.Page;
              if (parameters.Page)
              {
                navigate('/' + parameters.Page);
              } else
              {
                runCommands(parameters);
              }
            } else
            {
              setError(resp.errorMessage!);
            }
          })
          .catch((error) =>
          {
            console.log(error);
          });
      } else if (parameters.Pid && parameters.Password)
      {
        const envName = 'uat';
        const result = await participantLogin(parameters.Pid, setError, envName);

        if (result)
        {
          cookies.set('authUser', { pid: parameters.Pid }, { path: '/' });
          cookies.set('token', result.authToken, { path: '/' });
          cookies.set('type', 'participant', { path: '/' });
          const userObj = {
            username: parameters.Pid,
            email: parameters.Pid,
            userType: 'participant',
            token: result.authToken!,
            groups: [],
          };
          setUser(userObj);
          setUserType('participant');
          setIsAuthenticated(true);

          // if (parameters.Page) {
          //   navigate('/' + parameters.Page);
          // } else {
          //   runCommands(parameters);
          // }

          window.location.href = parameters.Page.startsWith('/')
            ? parameters.Page
            : '/' + parameters.Page;
        } else
        {
          setError('Thank you for your time but you are not allowed to access this page');
        }
      }
    }
    doMagic();
    // eslint-disable-next-line
  }, []);

  const assignConfig = (parameters: any) =>
  {
    if (parameters.logicValues)
    {
      logic.assign(parameters.logicValues?.variable, parameters.logicValues?.value);
    }
  };

  const runCommands = async (parameters: any) =>
  {
    if (parameters.PageCommands)
    {
      const engine = new PageEngine({
        tableName: 'testListPage',
        meta: {},
        data: parameters.PageCommands,
      });

      const rend_helper = new RenderHelper();

      rend_helper.navigate = navigate;
      await engine.processPage(rend_helper);
    }
  };

  return (
    <React.Fragment>
      <div className="container-fluid">
        <Container>
          <Row className="justify-content-center">
            <Col md={12}>
              <h4 className="flex-box-reverse"></h4>
              <Card className="overflow-hidden">
                <div className="p-2 text-center">{error && <div>{error}</div>}</div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MagicPage;
