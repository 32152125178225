import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LangServices } from '@lainaedge/platformshared';

const langService = LangServices.instance();

type Props = {
  options: Array<any>;
  setShowSearchResultBox: (b: boolean) => void;
  clearInput: () => void;
};

const useOutsideClick = (callback: any) =>
{
  const ref: any = React.useRef();

  React.useEffect(() =>
  {
    const handleClick = (event: any) =>
    {
      if (ref.current && !ref.current.contains(event.target))
      {
        callback();
      }
    };

    document.addEventListener('click', handleClick, true);

    return () =>
    {
      document.removeEventListener('click', handleClick, true);
    };
  }, [ref]);

  return ref;
};

const SearchResultBox = ({ options, clearInput, setShowSearchResultBox }: Props) =>
{
  const navigate = useNavigate();

  const handleCloseSearchResult = () =>
  {
    setShowSearchResultBox(false);
  };

  const ref = useOutsideClick(handleCloseSearchResult);

  const handleClickOption = (uuid: string) =>
  {
    navigate(`/page/participant_detail/${uuid}/0`);
    handleCloseSearchResult();
    clearInput();
  };

  return (
    <div className="search-result-box p-2" ref={ref}>
      <button
        type="button"
        className="close"
        data-dismiss="modal"
        aria-label="Close"
        onClick={handleCloseSearchResult}
      >
        ×
      </button>
      {options.length > 0 ? (
        <div className="search-result-table-wrapper">
          <table className="table sortable-table table-striped">
            <thead>
              <tr>
                <th>{langService.Translate('Participant ID')}</th>
                <th>{langService.Translate('Participant Name')}</th>
                <th>{langService.Translate('Email')}</th>
                <th>{langService.Translate('Phone')}</th>
              </tr>
            </thead>
            <tbody>
              {options.map((option: any, index: number) =>
              {
                return (
                  <tr key={option._id + index}>
                    <td
                      className={option.highlight_field?.includes('uuid') ? 'bold' : ''}
                      onClick={() => handleClickOption(option.uuid)}
                    >
                      {option.uuid}
                    </td>
                    <td className={option.highlight_field?.includes('name') ? 'bold' : ''}>
                      {option.first_name} {option.last_name}
                    </td>
                    <td className={option.highlight_field?.includes('email') ? 'bold' : ''}>
                      {option.email_for_survey_panel ? option.email_for_survey_panel : option.email}
                    </td>
                    <td>{option.phone_number ? option.phone_number : '-'}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div>{langService.Translate('No Result Found')}</div>
      )}
    </div>
  );
};

export default SearchResultBox;
