import React from 'react';

import { LangServices } from '@lainaedge/platformshared';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';
import { InputProps } from './types';

const langService = LangServices.instance();

/**
 * Dropdown component @extends FormInput
 *
 * @component Dropdown
 * @category FormElements
 */
export default class Dropdown extends FormInput
{
  constructor(props: InputProps)
  {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      options: [],
    };
  }

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleSelect = (e: any) =>
  {
    const field = this.props.formProps.field;

    if (this.isEditFieldOnModal())
    {
      this.props.formProps.handleChangeEditValues(field, e.target.value);
    } else
    {
      this.handleChangeText(field, e);
    }
  };

  /**
   * Renders Dropdown class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;
    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const fieldValue = this.isEditFieldOnModal()
      ? this.props.formProps.edit_values[field.field]
      : this.state.myFieldValue;

    let options: Array<any> = [];
    if (step.tableDef && step.dataDictionary)
    {
      const name = step.tableDef.getFieldLookupName(field.field);

      /** Represents a lookup table */
      const list = step.dataDictionary.getLookupTable(name);

      options = list;
    }

    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    let className = 'form-control drop-down';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <select
            className={className}
            name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
            onChange={this.handleSelect}
            disabled={this.isFieldDisabled()}
            value={[QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) ? '' : fieldValue}
          >
            <option value="">{langService.Translate('Select')}</option>
            {options.map((item: any) => (
              <option key={item.code} value={item.code}>
                {item.english}
              </option>
            ))}
          </select>
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
