import React, { Component } from 'react';

import { StepDashStat, StringOptions } from '@lainaedge/platformshared';
import { myProjectName } from 'Common/constants';
import { RenderHelper } from 'PageEngine/components/RenderHelper';
import styled from 'styled-components';

const DashStat = styled.div`
  display: table-cell;
  & > div {
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    background-clip: border-box;
    min-height: 1px;
    padding: 1px;
    //box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
    text-align: center;
  }
`;

const DashStatTitle = styled.div`
  flex: 1;
  font-size: 20px;
  line-height: 24px;
  background: white;
  white-space: nowrap;
  font-weight: 500;
  padding: 16px 20px;
  color: #495057;
`;

const DashStatStatus = styled.div`
  flex: 1;
  // height: 32px;
  font-size: 16px;
  line-height: 25px;
  color: white;
  padding: 3px 48px;
  white-space: nowrap;
`;

const DashStatButton = styled.div`
  flex: 1;
  flex-grow: 1;
  font-size: 16px;
  line-height: 25px;
  padding: 6px 90px !important;
  white-space: nowrap;
`;

const OldDashStat = styled.div`
  display: inline-block;
  word-wrap: break-word;
  border: 0 solid #f6f6f6;
  border-radius: 0.25rem;
  min-height: 1px;
  padding: 0.25rem;
  margin-bottom: 24px;
  //box-shadow: 0 0.75rem 1.5rem rgba(18, 38, 63, 0.03);
  text-align: center;

  div {
    background-color: #fff;
    padding: 1rem;
    cursor: pointer;
  }
`;

/**
 * Props for [[`PageDashStat`]] component
 */
export interface PageDashStatProps
{
  /** Used run processTextReplacement. */
  logic: any;
  /** Step data */
  step: StepDashStat;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageDashStat component
 *
 * @component PageDashStat
 * @category PageElements
 */
export default class PageDashStat extends Component<PageDashStatProps> {
  constructor(props: PageDashStatProps)
  {
    super(props);

    this.state = {};
  }

  /**
   * DashStat click handler. Redirects to option 1 of the step data.
   */
  handleClick = async () =>
  {
    const step = this.props.step;
    const targetUrl = this.props.logic.processTextReplacement(step.raw.option_1);
    this.props.renderer.navigate(targetUrl);
  };

  /**
   * Renders PageDashStat class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.step;
    const processedText = this.props.logic.processTextReplacement(step.text);
    const processedFieldID = this.props.logic.processTextReplacement(step.field_id);
    const idText = processedFieldID?.length == 0 ? <br /> : processedFieldID;

    const isQuery = step.field_id?.includes('Query');

    const opt = new StringOptions(step.option_2);

    const buttonText = opt.checkOption('Button');

    let wrapperStyle = 'dash-stat';
    if (opt.checkOption('Primary')) wrapperStyle += ' btn-primary';
    else if (opt.checkOption('Secondary')) wrapperStyle += ' btn-secondary';
    else if (opt.checkOption('Danger')) wrapperStyle += ' btn-danger';
    else if (opt.checkOption('Warning')) wrapperStyle += ' btn-warning';
    else if (opt.checkOption('Info')) wrapperStyle += ' btn-info';
    else if (opt.checkOption('Light')) wrapperStyle += ' btn-light';
    else if (opt.checkOption('Dark')) wrapperStyle += ' btn-dark';
    else if (opt.checkOption('Success')) wrapperStyle += ' btn-success';
    else wrapperStyle += ' btn-default';

    if (opt.checkOption('md3')) wrapperStyle += ' col-md-3';
    else if (opt.checkOption('md2')) wrapperStyle += ' col-md-2';
    else if (opt.checkOption('md4')) wrapperStyle += ' col-md-4';
    else if (opt.checkOption('md5')) wrapperStyle += ' col-md-5';
    else if (opt.checkOption('md6')) wrapperStyle += ' col-md-6';
    else if (opt.checkOption('md12')) wrapperStyle += ' col-md-12';

    if (opt.checkOption('w3')) wrapperStyle += ' col-3';
    else if (opt.checkOption('w2')) wrapperStyle += ' col-2';
    else if (opt.checkOption('w4')) wrapperStyle += ' col-4';
    else if (opt.checkOption('w5')) wrapperStyle += ' col-5';
    else if (opt.checkOption('w6')) wrapperStyle += ' col-6';
    else if (opt.checkOption('w7')) wrapperStyle += ' col-6';
    else if (opt.checkOption('w8')) wrapperStyle += ' col-6';
    else if (opt.checkOption('w12')) wrapperStyle += ' col-12';

    if (!buttonText) wrapperStyle += ' cursor-pointer';

    let buttonStyle = 'button cursor-pointer';
    if (opt.checkOption('Primary')) buttonStyle += ' primary';
    else if (opt.checkOption('Danger')) buttonStyle += ' danger';
    else if (opt.checkOption('Success')) buttonStyle += ' success';
    else if (opt.checkOption('Secondary')) buttonStyle += ' secondary';
    else buttonStyle += ' default';

    const hasMultiple = step.field_id?.includes(';;');
    const separates = processedFieldID?.split(';;');

    if (myProjectName != 'demo' && myProjectName != 'covid2')
    {
      return (
        <OldDashStat className="mini-stats-wid col-6 col-md-4 col-lg-3" onClick={this.handleClick}>
          <div>
            <p className="text-muted font-weight-medium">{processedText}</p>
            <h4 className="mb-0">{step.count}</h4>
          </div>
        </OldDashStat>
      );
    }

    return (
      <DashStat
        className={wrapperStyle}
        onClick={step.raw.option_1 && !buttonText ? this.handleClick : undefined}
      >
        <div>
          <DashStatTitle className={'dash-stat-title'}>{processedText}</DashStatTitle>
          {hasMultiple ? (
            <DashStatStatus className="mb-0 pl-1 pr-1 d-flex justify-content-between dash-stat-status">
              <span>{separates[0]}</span>
              <span>{separates[1]}</span>
            </DashStatStatus>
          ) : (
            <DashStatStatus className="mb-0 dash-stat-status">{isQuery ? step.count : idText}</DashStatStatus>
          )}
          {buttonText && (
            <DashStatButton className={buttonStyle} onClick={this.handleClick}>
              {buttonText}
            </DashStatButton>
          )}
        </div>
      </DashStat>
    );
  }
}
