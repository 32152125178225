import React, { Component } from 'react';

import { StepText } from '@lainaedge/platformshared';
import { DataContext } from 'Common/context/DataContext';

/**
 * Props for [[`PageTrace`]] component
 */
export interface PageTraceProps
{
  /** Used for displaying replaced text. */
  logic: any;
  /** Step data. */
  step: StepText;
}

/**
 * PageTrace component
 *
 * @component PageTrace
 * @category PageElements
 */
export default class PageTrace extends Component<PageTraceProps> {
  static contextType = DataContext;

  constructor(props: PageTraceProps)
  {
    super(props);
    this.state = {};
  }

  /**
   * Renders PageTrace class component.
   * @see demo page /page/text_styles in demo study.
   */
  public render(): JSX.Element
  {
    const step = this.props.step;
    if (!this.context.enableTrace)
    {
      return <></>;
    }

    return (
      <div className="traceSection row">
        <div className="col-md-8">
          {step.raw._row}. {this.props.logic.processTextReplacement(step.text)}
        </div>
        <div className="col-md-2">{this.props.logic.processTextReplacement(step.field_id)}</div>
        <div className="col-md-1">{this.props.logic.processTextReplacement(step.option_1)}</div>
        <div className="col-md-1">{this.props.logic.processTextReplacement(step.option_2)}</div>
      </div>
    );
  }
}
