import React, { Component } from 'react';
import { Col } from 'reactstrap';

import { StepDashButton } from '@lainaedge/platformshared';
import { RenderHelper } from 'PageEngine/components/RenderHelper';
import styled from 'styled-components';

const DashButtonIcon = styled.div`
  background: white;
  border-radius: 1rem;
  border: 2px solid #f1b44c;
  padding: 2rem;

  i {
    font-size: 2rem;
  }
`;

const DashButton = styled.button`
  margin-top: -1rem;
  width: 80%;
  background: #f1b44c;
  border-radius: 1rem;
  border: none;
  padding: 1rem 0.5rem;
  color: white;
`;

/**
 * Props for [[`PageDashButton`]] component
 */
export interface PageDashButtonProps
{
  /** Used run processTextReplacement. */
  logic: any;
  /** Step data.*/
  step: StepDashButton;
  /** Used for redirecting. */
  renderer: RenderHelper;
}

/**
 * PageDashButton component
 *
 * @component PageDashButton
 * @category PageElements
 */
export default class PageDashButton extends Component<PageDashButtonProps> {
  constructor(props: PageDashButtonProps)
  {
    super(props);

    this.state = {};
  }

  /**
   * Button click handler.
   *
   * @remarks
   * Used for redirecting to step.raw.option_1
   */
  handleClick = async () =>
  {
    const step = this.props.step;
    this.props.renderer.navigate(step.raw.option_1);
  };

  /**
   * Renders PageDashButton class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.step;
    const processedText = this.props.logic.processTextReplacement(step.text);
    return (
      <Col className="text-center justify-content-center">
        <DashButtonIcon className="pt-3 container-fluid d-flex justify-content-center">
          <i className={step.icon}></i>
        </DashButtonIcon>
        <DashButton type="button" color="warning" onClick={this.handleClick}>
          <p className="font-weight-medium mt-1 mb-1">{processedText}</p>
        </DashButton>
      </Col>
    );
  }
}
