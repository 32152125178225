import React from 'react';
import Autosuggest from 'react-autosuggest';

import { StepForm, LangServices } from '@lainaedge/platformshared';
import { AxiosError } from 'axios';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';
import { getWhoDrugDetails, searchWhoDrug } from 'Common/services';
import debounce from 'debounce';

import FormInput from './FormInput';
import { InputProps } from './types';

const langService = LangServices.instance();

/**
 * MedicationSuggest component @extends FormInput
 *
 * @component MedicationSuggest
 * @category FormElements
 */
export default class MedicationSuggest extends FormInput
{
  constructor(props: InputProps)
  {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      options: [],
      isOther: false,
    };
  }

  componentDidMount()
  {
    const field = this.props.formProps.field;
    const medicationStr = this.props.formProps.step.getValueDatabase(field.field);
    if (medicationStr)
    {
      let medication: any = {};
      let isOther = false;
      let medicationName = '';
      try
      {
        medication = JSON.parse(medicationStr);
        isOther = medication.isOther;
        medicationName = medication.name;
      } catch (error) { }
      this.setState({
        options: [medication],
        myFieldValue: this.isEditFieldOnModal() ? '' : medicationName,
        isOther,
      });
    }
  }

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  handleChangeMedicationSuggest = (field: StepForm.FieldInfo, e: any) =>
  {
    this.setState({ myFieldValue: e.target.value });
    this.searchDelayed(e.target.value);
  };

  getSuggestionsData = async (queryStr: string) =>
  {
    try
    {
      if (!queryStr)
      {
        // this.setState({ options: [] });
        // const field = this.props.formProps.field;
        /* Comment the following code to handl the time synchronization issue on the UAT servers, 
        this gets called after the actual save data, which results in clearing the value */
        // if (this.isEditFieldOnModal())
        // {
        //   this.props.formProps.handleChangeEditValues(field, '');
        // } else
        // {
        //   this.props.formProps.step.setValueFromUser(field.field, '');
        // }
        // if (this.props.updateMedication)
        // {
        //   this.props.updateMedication({});
        // }
        return;
      }
      const optionsData = await searchWhoDrug(queryStr);
      if (optionsData && optionsData.data)
      {
        this.setState({ options: optionsData.data });
      }
    } catch (err: any | AxiosError)
    {
      console.log(err.response.data);
    }
  };

  searchDelayed = debounce(this.getSuggestionsData, 500);

  handleToggleOtherCheckbox = () =>
  {
    const newStatus = !this.state.isOther;

    const field = this.props.formProps.field;
    const selected = { name: this.state.myFieldValue, isOther: newStatus };

    if (this.isEditFieldOnModal())
    {
      this.props.formProps.handleChangeEditValues(field, selected ? JSON.stringify(selected) : '');
    } else
    {
      this.props.formProps.step.setValueFromUser(
        field.field,
        selected ? JSON.stringify(selected) : '',
      );
    }

    if (this.props.updateMedication)
    {
      this.props.updateMedication(selected ? selected : {});
    }

    this.setState({ isOther: newStatus, myFieldValue: newStatus ? this.state.myFieldValue : '' });
  };

  /**
   * Used to change the value of a field. ( for other text input )
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleChangeMedicationSuggestText = (field: StepForm.FieldInfo, e: any) =>
  {
    const selected = { name: e.target.value, isOther: true };

    if (this.isEditFieldOnModal())
    {
      this.props.formProps.handleChangeEditValues(field, selected ? JSON.stringify(selected) : '');
    } else
    {
      this.props.formProps.step.setValueFromUser(
        field.field,
        selected ? JSON.stringify(selected) : '',
      );
    }
    this.setState({ myFieldValue: e.target.value });
  };

  /**
     * Called when the user selectes a value from the suggestions list
     *
     * @param event - Event data.
     * @param suggestion - Suggestion object
     * @param suggestionValue - Suggestion string value
     * @returns Void
     */
  onSuggestionSelected = (event: any, { suggestion, suggestionValue }: any) =>
  {
    const options = this.state.options || [];

    /** Represents the first item in the list which english text is equal to value. */
    const selected = options.find((item) => item.name === suggestionValue);

    if (this.isEditFieldOnModal())
    {
      this.getMedicationInfo(selected.id);
    } else
    {
      this.getMedicationInfo(selected.id);
    }
    this.setState({ myFieldValue: suggestionValue });
  }

  onSuggestionsFetchRequested = ({ value }: any) =>
  {
  }

  onSuggestionsClearRequested = () =>
  {
  };

  getMedicationInfo = async (medicationId: string) =>
  {
    if (medicationId && this.props.toggleMedicationLoading)
    {
      this.props.toggleMedicationLoading(true);
      const dosesData = await getWhoDrugDetails(medicationId);
      if (dosesData && dosesData.data)
      {
        if (dosesData.data.length > 0)
        {
          const selected = dosesData.data[0];
          const field = this.props.formProps.field;
          if (this.isEditFieldOnModal())
          {
            this.props.formProps.handleChangeEditValues(
              field,
              selected ? JSON.stringify(selected) : '',
            );
          } else
          {
            this.props.formProps.step.setValueFromUser(
              field.field,
              selected ? JSON.stringify(selected) : '',
            );
          }
          if (this.props.updateMedication)
          {
            this.props.updateMedication(selected ? selected : {});
          }
        }
      }
      this.props.toggleMedicationLoading(false);
    }
  };

  /**
   * Renders MedicationSuggest class component.
   */
  public render(): JSX.Element
  {
    const is_disabled = this.props.formProps.is_disabled;

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    const options = this.state.options || [];
    let fieldValue = this.state.myFieldValue;

    try
    {
      const fieldObj = JSON.parse(fieldValue);
      if (fieldObj?.name)
      {
        fieldValue = fieldObj.name;
      }
    } catch (error) { }

    return (
      <>
        <div className="d-inline-block medication-suggest mr-2 mb-1">
          <Autosuggest
            suggestions={options}
            onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
            onSuggestionsClearRequested={this.onSuggestionsClearRequested}
            getSuggestionValue={(item: any) => item?.name}
            onSuggestionSelected={this.onSuggestionSelected}
            shouldRenderSuggestions={(value, reason) =>
            {
              return reason == 'input-changed' || reason == 'input-focused' || reason == 'render' || reason == 'suggestions-updated';
            }}
            renderSuggestion={(item: any, { isHighlighted }) =>
            {
              return (<div
                key={item.id}
                style={{ background: isHighlighted ? 'lightgray' : 'white', padding: '5px' }}
                dangerouslySetInnerHTML={{
                  __html: item?.name?.replace(fieldValue, `<strong>${fieldValue}</strong>`),
                }}
              ></div>);
            }}
            inputProps={{
              className: this.state.error && !this.state.isOther
                ? 'is-invalid form-control autocomplete'
                : 'form-control autocomplete',
              disabled:
                this.isEditMode() ||
                is_disabled ||
                this.props.formProps.hide_fields[field.field] ||
                this.props.formProps.field.enabled === false ||
                this.state.isOther,
              name: this.isEditFieldOnModal() ? 'e' + field.field : field.field,
              value: [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) || this.state.isOther
                ? ''
                : fieldValue,
              onChange: (e: any) => { this.handleChangeMedicationSuggest(field, e); }
            }}
          />
          {!this.state.isOther && this.renderValidationError()}
          <div className="custom-control custom-checkbox mt-1 mb-1">
            <input
              type="checkbox"
              id={
                this.isEditFieldOnModal()
                  ? 'edit' + field.field + 'medication-suggest-checkbox'
                  : field.field + 'medication-suggest-checkbox'
              }
              className="custom-control-input"
              name={
                this.isEditFieldOnModal()
                  ? 'edit' + field.field + 'medication-suggest-checkbox'
                  : field.field + 'medication-suggest-checkbox'
              }
              checked={this.state.isOther}
              disabled={
                this.isEditMode() ||
                is_disabled ||
                this.props.formProps.hide_fields[field.field] ||
                this.props.formProps.field.enabled === false
              }
              onChange={this.handleToggleOtherCheckbox}
            />
            <label
              className="custom-control-label"
              htmlFor={
                this.isEditFieldOnModal()
                  ? 'edit' + field.field + 'medication-suggest-checkbox'
                  : field.field + 'medication-suggest-checkbox'
              }
            >
              {langService.Translate('Other')}
            </label>
          </div>
          <input
            className={
              this.state.error && this.state.isOther ? 'is-invalid form-control' : 'form-control'
            }
            type="text"
            data-testid="medication-suggest-other-input"
            name={this.isEditFieldOnModal() ? 'e' + field.field + 'other' : field.field + 'other'}
            value={
              [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) || !this.state.isOther
                ? ''
                : fieldValue
            }
            disabled={
              this.isEditMode() ||
              is_disabled ||
              this.props.formProps.hide_fields[field.field] ||
              this.props.formProps.field.enabled === false ||
              !this.state.isOther
            }
            onChange={(e: any) =>
            {
              this.handleChangeMedicationSuggestText(field, e);
            }}
          />
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.state.isOther && this.renderValidationError()}
      </>
    );
  }
}
