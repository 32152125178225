import React from 'react';

import { StepForm, LangServices } from '@lainaedge/platformshared';
import { QNS_VALUE, UNAVAILABLE_VALUE } from 'Common/constants';

import FormInput from './FormInput';
import { InputProps } from './types';

const langService = LangServices.instance();

/**
 * MedicationSuggest component @extends FormInput
 *
 * @component MedicationSuggest
 * @category FormElements
 */
export default class MedicationSuggest extends FormInput
{
  constructor(props: InputProps)
  {
    super(props);

    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;

    this.state = {
      myAlign: this.props.formProps.step.getValueAlign(field.field),
      myUnits: this.props.formProps.step.getValueUnits(field.field),
      myFieldValue: this.getValue(field),
      options: [],
      isOther: false,
    };
  }

  componentDidMount()
  {
    const field = this.props.formProps.field;
    const medicationStr = this.props.formProps.step.getValueDatabase('medication_name');
    const doseStr = this.props.formProps.step.getValueDatabase(field.field);

    if (doseStr || medicationStr)
    {
      let medication: any = {};
      let dose: any = {};
      let doseValue = '';
      let isOther = false;
      try
      {
        medication = JSON.parse(medicationStr);
        dose = JSON.parse(doseStr);
        doseValue = dose.name;
        isOther = dose.isOther;
      } catch (err) { }
      this.setState({
        options: medication?.dose ?? [],
        myFieldValue: this.isEditFieldOnModal() ? '' : doseValue,
        isOther,
      });
    }
  }

  shouldComponentUpdate = (nextProps: any) =>
  {
    if (this.props.medication != nextProps.medication)
    {
      const field = this.props.formProps.field;
      const doseOptions = nextProps.medication?.dose;
      if (doseOptions?.length > 0)
      {
        this.setState({ options: doseOptions, myFieldValue: '' });
        this.props.formProps.step.setValueFromUser(field.field, '');
      } else
      {
        this.setState({ options: [], myFieldValue: '' });
        this.props.formProps.step.setValueFromUser(field.field, '');
      }
    }
    return true;
  };

  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleSelect = (e: any) =>
  {
    const field = this.props.formProps.field;
    const selected = { name: e.target.value, isOther: false };
    this.setState({ myFieldValue: e.target.value });

    if (this.isEditFieldOnModal())
    {
      this.props.formProps.handleChangeEditValues(field, selected ? JSON.stringify(selected) : '');
    } else
    {
      this.props.formProps.step.setValueFromUser(
        field.field,
        selected ? JSON.stringify(selected) : '',
      );
    }
  };

  handleToggleOtherCheckbox = () =>
  {
    const newStatus = !this.state.isOther;

    const selected = { name: this.state.myFieldValue, isOther: newStatus };
    const field = this.props.formProps.field;

    if (this.isEditFieldOnModal())
    {
      this.props.formProps.handleChangeEditValues(field, selected ? JSON.stringify(selected) : '');
    } else
    {
      this.props.formProps.step.setValueFromUser(
        field.field,
        selected ? JSON.stringify(selected) : '',
      );
    }

    this.setState({ isOther: newStatus, myFieldValue: newStatus ? this.state.myFieldValue : '' });
  };

  /**
   * Used to change the value of a field. ( for other text input )
   *
   * @param field - Points to the field.
   * @param val - New value to be assigned to the field.
   * @returns Void
   */
  handleChangeMedicationDoseText = (field: StepForm.FieldInfo, e: any) =>
  {
    const selected = { name: e.target.value, isOther: true };

    if (this.isEditFieldOnModal())
    {
      this.props.formProps.handleChangeEditValues(field, selected ? JSON.stringify(selected) : '');
    } else
    {
      this.props.formProps.step.setValueFromUser(
        field.field,
        selected ? JSON.stringify(selected) : '',
      );
    }
    this.setState({ myFieldValue: e.target.value });
  };

  /**
   * Renders MedicationSuggest class component.
   */
  public render(): JSX.Element
  {
    const step = this.props.formProps.step;
    /** Initialize the value of the state from the database value */
    const field = this.props.formProps.field;
    const is_on_modal = this.props.formProps.is_on_modal;
    const is_disabled = this.props.formProps.is_disabled;

    let className = 'form-control';
    if (
      this.state.error &&
      !is_disabled &&
      !this.props.formProps.field.enabled &&
      (is_on_modal || !step.is_edit_mode)
    )
      className += ' is-invalid';

    let options = this.state.options ?? [];
    let fieldValue = this.state.myFieldValue;

    if (Object.prototype.toString.call(options) !== '[object Array]')
    {
      options = [options];
    }

    try
    {
      const fieldObj = JSON.parse(fieldValue);
      if (fieldObj?.name)
      {
        fieldValue = fieldObj.name;
      }
    } catch (error) { }

    return (
      <>
        <div className="d-inline-block mr-2 mb-1">
          <select
            className="form-control drop-down"
            onChange={this.handleSelect}
            id={
              this.isEditFieldOnModal()
                ? 'e' + field.field + 'medication-dose'
                : field.field + 'medication-dose'
            }
            name={
              this.isEditFieldOnModal()
                ? 'e' + field.field + 'medication-dose'
                : field.field + 'medication-dose'
            }
            disabled={this.isFieldDisabled() || this.props.medicationLoading}
            value={
              [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) || this.state.isOther
                ? ''
                : fieldValue
            }
          >
            <option value="">{langService.Translate('Select')}</option>
            {options.map((item: string) => (
              <option key={item} value={item?.toLowerCase()}>
                {item}
              </option>
            ))}
          </select>
          {this.props.medicationLoading && (
            <div className="medication-dose-loading">
              <div className="spinner-chase">
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
                <div className="chase-dot"></div>
              </div>
            </div>
          )}
          <div className="custom-control custom-checkbox mt-1 mb-1">
            <input
              type="checkbox"
              id={
                this.isEditFieldOnModal()
                  ? 'e' + field.field + 'medication-dose-checkbox'
                  : field.field + 'medication-dose-checkbox'
              }
              className="custom-control-input"
              name={
                this.isEditFieldOnModal()
                  ? 'e' + field.field + 'medication-dose-checkbox'
                  : field.field + 'medication-dose-checkbox'
              }
              checked={this.state.isOther}
              disabled={
                this.isEditMode() ||
                is_disabled ||
                this.props.formProps.hide_fields[field.field] ||
                this.props.formProps.field.enabled === false
              }
              onChange={this.handleToggleOtherCheckbox}
            />
            <label
              className="custom-control-label"
              htmlFor={
                this.isEditFieldOnModal()
                  ? 'e' + field.field + 'medication-dose-checkbox'
                  : field.field + 'medication-dose-checkbox'
              }
            >
              {langService.Translate('Other')}
            </label>
          </div>
          <input
            className={className}
            type="text"
            data-testid="medication-dose-other-input"
            name={this.isEditFieldOnModal() ? 'e' + field.field + 'other' : field.field + 'other'}
            value={
              [QNS_VALUE, UNAVAILABLE_VALUE].includes(fieldValue) || !this.state.isOther
                ? ''
                : fieldValue
            }
            disabled={
              this.isEditMode() ||
              is_disabled ||
              this.props.formProps.hide_fields[field.field] ||
              this.props.formProps.field.enabled === false ||
              !this.state.isOther
            }
            onChange={(e: any) =>
            {
              this.handleChangeMedicationDoseText(field, e);
            }}
          />
        </div>
        {this.renderQnsAndUnavailableSwitches()}
        {this.renderValidationError()}
      </>
    );
  }
}
