import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Label, Row, UncontrolledTooltip } from 'reactstrap';

import { DataTools, LangServices } from '@lainaedge/platformshared';
import logo from 'Common/assets/images/logo-laina.png';
import vector2 from 'Common/assets/images/vector2.png';
import { myProjectName, myServerEnv } from 'Common/constants';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import toastr from 'toastr';
import * as Yup from 'yup';

import packageJson from '../../../package.json';

const langService = LangServices.instance();
const DEMO_PID_PASSWORD = 'laina1234';

/**
 * CreateMagicLink component.
 *
 * @remarks
 * Page where you can create a new magic link
 *
 * @component CreateMagicLink
 * @category Page
 */
const CreateMagicLink = (): JSX.Element =>
{
  const { versionInfo, dashTitle } = useData();
  const { error } = useAuth();
  const [magicLink, SetMagicLink] = useState('');

  async function handleValidSubmit(values: { participantId: string; targetPage: string })
  {
    const testValue2 = {
      Pid: values.participantId, // '9995-594-362'
      Password: DEMO_PID_PASSWORD,
      Page: values.targetPage, // 'page/participant_start/9995-594-362'
    };
    const stringValue = DataTools.encodeObjectCompressed(testValue2);

    SetMagicLink(window.location.origin + `/system/magicPage?code=${stringValue}`);
  }

  const copyTextToClipboard = (text: string) =>
  {
    navigator.clipboard.writeText(text);
    toastr.success(langService.Translate('Magic Link Copied to Clipboard'), 'Success');
  };

  const titleParts = dashTitle.split('(');

  return (
    <React.Fragment>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2"></i>
        </Link>
      </div>
      <div className="login-page account-pages pt-sm-3">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="login-header">
                  <div className="title">
                    <img src={logo} alt="" className="img-fluid" />
                  </div>
                  <img src={vector2} alt="" className="img-fluid" />
                </div>
                <div className="card-body pb-0">
                  <div className="text-darkblue p-2">
                    <h5 className="text-darkblue">{titleParts[0]}</h5>
                    <p className="mb-0">{titleParts.length === 2 && '(' + titleParts[1]}</p>
                  </div>
                </div>
                <CardBody className="">
                  <div className="p-2">
                    <Formik
                      enableReinitialize={true}
                      initialValues={{
                        participantId: '',
                        targetPage: '',
                      }}
                      validationSchema={Yup.object().shape({
                        participantId: Yup.string().required(langService.Translate('Enter Participant ID')),
                        targetPage: Yup.string().required(langService.Translate('Enter Target Page Link')),
                      })}
                      onSubmit={(values) =>
                      {
                        handleValidSubmit(values);
                      }}
                    >
                      {({ errors, touched }) => (
                        <Form className="form-horizontal">
                          {error && error ? <Alert color="danger">{error}</Alert> : null}
                          <div className="form-group">
                            <Label for="participantId" className="form-label">
                              {langService.Translate('Participant ID')}
                            </Label>
                            <Field
                              name="participantId"
                              id="participantId"
                              type="input"
                              placeholder={langService.Translate('Enter Participant ID')}
                              className={
                                'form-control' +
                                (errors.participantId && touched.participantId ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="participantId"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="form-group">
                            <Label for="targetPage" className="form-label">
                              {langService.Translate('Target Page Link')}
                            </Label>
                            <Field
                              name="targetPage"
                              id="targetPage"
                              type="input"
                              placeholder={langService.Translate('Enter Target Page Link')}
                              className={
                                'form-control' +
                                (errors.targetPage && touched.targetPage ? ' is-invalid' : '')
                              }
                            />
                            <ErrorMessage
                              name="targetPage"
                              component="div"
                              className="invalid-feedback"
                            />
                          </div>

                          <div className="mt-3">
                            <button
                              className="btn btn-login btn-block waves-effect waves-light save-btn"
                              type="submit"
                            >
                              {langService.Translate('Create Link')}
                            </button>
                          </div>

                          {magicLink && (
                            <div className="mt-3">
                              <span
                                role="button"
                                id="magic-link"
                                onClick={() => copyTextToClipboard(magicLink)}
                              >
                                {magicLink}
                              </span>
                              <UncontrolledTooltip placement="top" target={`magic-link`}>
                                {langService.Translate('You can copy the link by clicking on it')}
                              </UncontrolledTooltip>
                            </div>
                          )}
                          {myServerEnv === 'uat' && (
                            <div className="mt-3 login-page-small">
                              This is the User Acceptance Test (UAT) Environment. If you are at the
                              site or looking for production data{' '}
                              <a
                                className="login-notice"
                                href={'https://prod.' + myProjectName + '.trial.lainaent.com/'}
                              >
                                Click Here.
                              </a>
                            </div>
                          )}

                          {myServerEnv === 'prod' && (
                            <div className="mt-3">
                              This is the Production Server for {dashTitle}
                            </div>
                          )}
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="text-center login-page-small">
                CPS : {versionInfo?.clientPlatformSharedVersion}, SPS :{' '}
                {versionInfo?.serverPlatformSharedVersion}, UI : {packageJson.version}, TDD:{' '}
                {versionInfo?.serverTDDVersion}, V: {versionInfo?.version}
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment >
  );
};

export default CreateMagicLink;
