import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';

import { DataServices, LangServices } from '@lainaedge/platformshared';
import { IAuth } from 'Common/types';

const cookies = new Cookies();
const dataService = DataServices.instance();
const langService = LangServices.instance();

let token = cookies.get('token');
const authUser = cookies.get('authUser');

type IAuthContext = {
  isAuthenticated: boolean;
  setIsAuthenticated: (val: boolean) => void;
  user: IAuth;
  setUser: (val: IAuth) => void;
  userType: string;
  setUserType: (val: string) => void;
  error: string;
  setError: (val: string) => void;
  success: string;
  setSuccess: (val: string) => void;
  loading: boolean;
  setLoading: (val: boolean) => void;
  clearState: () => void;
  logout: () => void;
  defaultRoute: string;
  setDefaultRoute: (val: string) => void;
};

export const AuthContext: React.Context<IAuthContext> = React.createContext({} as IAuthContext);

export const AuthProvider = ({ children }: { children: any }): JSX.Element =>
{
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(!!authUser);
  const [user, setUser] = useState<IAuth>({
    username: '',
    email: '',
    role: '',
    token: '',
    userType: '',
    firstName: '',
    lastName: '',
    groups: [],
  });
  const [userType, setUserType] = useState<string>(cookies.get('type') || '');
  const [error, setError] = useState<string>('');
  const [success, setSuccess] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [defaultRoute, setDefaultRoute] = useState('');

  const clearState = () =>
  {
    setError('');
    setSuccess('');
  };

  const logout = () =>
  {
    /** Set redirect Url before log out */
    cookies.set('redirectUrl', document.location.pathname, { path: '/' });
    cookies.remove('authUser', { path: '/' });
    cookies.remove('token', { path: '/' });
    cookies.remove('type', { path: '/' });
    setIsAuthenticated(false);
  };

  useEffect(() =>
  {
    if (token)
    {
      dataService.authUserFromToken(token).then((resp) =>
      {
        setIsAuthenticated(resp.isValidLogin);
        if (!resp.isValidLogin)
        {
          cookies.remove('authUser', { path: '/' });
          cookies.remove('token', { path: '/' });
          cookies.remove('type', { path: '/' });
          token = '';
          window.location.reload();
        }
      });
    }

    const defaultLang = new URLSearchParams(location.search).get('L') || '';
    if (defaultLang)
    {
      langService.setLanguage(defaultLang);
    }
  }, []);

  useEffect(() =>
  {
    setUser(authUser);
  }, [authUser]);

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        setIsAuthenticated,
        user,
        setUser,
        userType,
        setUserType,
        error,
        setError,
        success,
        setSuccess,
        loading,
        setLoading,
        clearState,
        logout,
        defaultRoute,
        setDefaultRoute,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = (): IAuthContext => React.useContext(AuthContext);
