import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Button, Card, CardBody, Container, Spinner } from 'reactstrap';

import { DataServices, LangServices } from '@lainaedge/platformshared';
import { useData } from 'Common/context/DataContext';
import toastr from 'toastr';

const empty_validation_msg = 'This field is required and may not be left empty.';

const dataService = DataServices.instance();
const langService = LangServices.instance();

const cookies = new Cookies();

/**
 * ChangePassword component.
 *
 * @remarks
 * Change Password page where the user can change the password
 *
 * @component ChangePassword
 * @category Page
 */
const ChangePassword = (): JSX.Element =>
{
  const { setPageTitle } = useData();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [values, setValues] = useState({
    current_pwd: '',
    new_pwd: '',
    confirm_pwd: '',
  });

  const [errors, setErrors] = useState({
    current_pwd: '',
    new_pwd: '',
    confirm_pwd: '',
  });

  useEffect(() =>
  {
    setPageTitle('Change Password')
  }, []);

  const handleChangePassword = () =>
  {
    SetIsSubmitting(true);
    const nErrors = {
      current_pwd: '',
      new_pwd: '',
      confirm_pwd: '',
    };
    if (values['new_pwd'] !== values['confirm_pwd'])
    {
      nErrors['confirm_pwd'] = langService.Translate('Confirm password does not match');
    }
    if (values['new_pwd'].length < 8)
    {
      nErrors['new_pwd'] = langService.Translate('Password should be more than 8 characters');
    }
    if (values['current_pwd'] === '') nErrors['current_pwd'] = empty_validation_msg;
    if (values['new_pwd'] === '') nErrors['new_pwd'] = empty_validation_msg;
    if (values['confirm_pwd'] === '') nErrors['confirm_pwd'] = empty_validation_msg;

    setErrors(nErrors);

    if (
      nErrors['current_pwd'] === '' &&
      nErrors['new_pwd'] === '' &&
      nErrors['confirm_pwd'] === ''
    )
    {
      dataService
        .changePassword(values['current_pwd'], values['new_pwd'])
        .then((resp) =>
        {
          if (resp.success)
          {
            // Your password has been successfully changed.
            toastr.success(resp.message, 'Success');
            cookies.remove('authUser', { path: '/' });
            setTimeout(() =>
            {
              document.location.reload();
            }, 2000);
          } else
          {
            toastr.error(resp.message, 'Failure');
          }
        })
        .catch((error) =>
        {
          console.log('Change Password Error', error);
        });
    }
    SetIsSubmitting(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Card className="overflow-hidden">
            <CardBody className="pt-4">
              <h4>{langService.Translate('Change Password')}</h4>
              <div>
                <label className="col-md-12 col-form-label">{langService.Translate('Current Password')}</label>
                <div className="col-md-12">
                  <input
                    className={errors.current_pwd ? 'is-invalid form-control' : 'form-control'}
                    type="password"
                    name={'current_pwd'}
                    value={values['current_pwd']}
                    onChange={(e: any) =>
                    {
                      setValues({ ...values, current_pwd: e.target.value });
                      if (errors.current_pwd === empty_validation_msg && e.target.value !== '')
                        setErrors({ ...errors, current_pwd: '' });
                    }}
                  />
                </div>
                <div className="col-md-12">
                  {errors.current_pwd && (
                    <span className="validation-error">{errors.current_pwd}</span>
                  )}
                </div>
              </div>

              <div>
                <label className="col-md-12 col-form-label">{langService.Translate('New Password')}</label>
                <div className="col-md-12">
                  <input
                    className={errors.new_pwd ? 'is-invalid form-control' : 'form-control'}
                    type="password"
                    name={'new_pwd'}
                    value={values['new_pwd']}
                    onChange={(e: any) =>
                    {
                      setValues({ ...values, new_pwd: e.target.value });
                      if (errors.new_pwd === empty_validation_msg && e.target.value !== '')
                        setErrors({ ...errors, new_pwd: '' });
                    }}
                  />
                </div>
                <div className="col-md-12">
                  {errors.new_pwd && <span className="validation-error">{errors.new_pwd}</span>}
                </div>
              </div>

              <div>
                <label className="col-md-12 col-form-label">{langService.Translate('Confirm Password')}</label>
                <div className="col-md-12">
                  <input
                    className={errors.confirm_pwd ? 'is-invalid form-control' : 'form-control'}
                    type="password"
                    name={'confirm_pwd'}
                    value={values['confirm_pwd']}
                    onChange={(e: any) =>
                    {
                      setValues({ ...values, confirm_pwd: e.target.value });
                      if (errors.confirm_pwd === empty_validation_msg && e.target.value !== '')
                        setErrors({ ...errors, confirm_pwd: '' });
                    }}
                  />
                </div>
                <div className="col-md-12">
                  {errors.confirm_pwd && (
                    <span className="validation-error">{errors.confirm_pwd}</span>
                  )}
                </div>
              </div>

              <div className="col-md-12 mt-2 mb-2">
                <Button
                  className="save-btn"
                  type="button"
                  color="primary"
                  disabled={isSubmitting}
                  onClick={() => handleChangePassword()}
                >
                  {langService.Translate('Submit')}
                  {isSubmitting && <Spinner className="spinner" />}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ChangePassword;
