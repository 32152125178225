import React from 'react';
import { Editor } from 'react-draft-wysiwyg';

import { StepForm } from '@lainaedge/platformshared';
import { convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

import FormInput from './FormInput';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

/**
 * Converts editorState to HTML format.
 *
 * @param editorState - Draft content state
 * @returns Converted raw draft state to HTML format
 */
const getHtml = (editorState: any) => draftToHtml(convertToRaw(editorState.getCurrentContent()));

/**
 * MemoInput component
 *
 * @component MemoInput
 * @category FormElements
 */
export default class MemoInput extends FormInput
{
  /**
   * Used to change the value of a field.
   *
   * @param field - Points to the field.
   * @param e - Used to set the value.
   * @returns Void
   */
  handleChangeMemo = (field: StepForm.FieldInfo, e: any) =>
  {
    const value = getHtml(e);
    this.setValue(field, value);
  };

  /**
   * Renders MemoInput class component.
   */
  public render(): JSX.Element
  {
    const is_disabled = this.props.formProps.is_disabled;
    const field = this.props.formProps.field;

    return (
      <>
        <Editor
          toolbarClassName="toolbarClassName"
          wrapperClassName="memo-wrapper"
          editorClassName="editorClassName"
          toolbar={{
            options: ['inline', 'list', 'textAlign'],
            inline: {
              inDropdown: false,
              options: ['bold', 'italic', 'underline'],
            },
            list: { inDropdown: false },
            textAlign: { inDropdown: false },
          }}
          name={this.isEditFieldOnModal() ? 'e' + field.field : field.field}
          rawContentState={
            this.isEditFieldOnModal()
              ? this.props.formProps.edit_values[field.field]
              : this.state.myFieldValue
          }
          disabled={
            this.isEditMode() ||
            is_disabled ||
            this.props.formProps.hide_fields[field.field] ||
            this.props.formProps.field.enabled === false
          }
          onEditorStateChange={(e: any) =>
          {
            if (this.isEditFieldOnModal())
            {
              this.props.formProps.handleChangeEditValues(field, getHtml(e));
            } else
            {
              this.handleChangeMemo(field, e);
            }
          }}
        />
        {this.renderValidationError()}
      </>
    );
  }
}
