import React, { Component } from 'react';
import { Table } from 'reactstrap';

import { DataRecord, LangServices } from '@lainaedge/platformshared';
import moment from 'moment';

const langService = LangServices.instance();

/**
 * Props for [[`LockHistoryItem`]] component
 */
export interface LockHistoryItemProps
{
  records: DataRecord[];
}

/**
 * LockHistoryItem component
 *
 * @component LockHistoryItem
 * @category FormElements
 */
export default class LockHistoryItem extends Component<LockHistoryItemProps> {
  /**
   * Renders LockHistoryItem class component.
   */
  public render(): JSX.Element
  {
    return (
      <>
        <div className="table-responsive field-history-table">
          <label>{langService.Translate('Lock History')}</label>
          <Table className="table table-sm m-0">
            <thead>
              <tr>
                <th className="date">{langService.Translate('Date')}</th>
                <th className="user">{langService.Translate('User')}</th>
                <th>{langService.Translate('Event')}</th>
              </tr>
            </thead>
            <tbody>
              {this.props.records.map((record: DataRecord) =>
              {
                return (
                  <tr key={record.id}>
                    <th className="date" scope="row">
                      {moment(record.data.event_dt_local).format('YYYY-MM-DD')}
                    </th>
                    <td className="user">{record.data.reported_by}</td>
                    <td>{record.data.event_text}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
