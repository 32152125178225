import React, { useEffect, useState } from 'react';
import { Cookies } from 'react-cookie';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Card, CardBody, Col, Container, Label, Row, Spinner } from 'reactstrap';

import { LangServices } from '@lainaedge/platformshared';
import { GenericResult } from '@lainaedge/platformshared/src/types/GenericResult';
import { ParticipantAuthResult } from '@lainaedge/platformshared/src/types/ParticipantAuthResult';
import logo from 'Common/assets/images/logo-laina.png';
import vector2 from 'Common/assets/images/vector2.png';
import { useAuth } from 'Common/context/AuthContext';
import { useData } from 'Common/context/DataContext';
import { sendConfirmationCode, verifyPhoneCode } from 'Common/services';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const cookies = new Cookies();
const langService = LangServices.instance();

const codeTimeout = 30;

/**
 * Authentication/MobileLogin component.
 *
 * @remarks
 * Page where data monitor can reset password
 *
 * @component MobileLogin
 * @category Page
 */
const MobileLoginPage = (): JSX.Element =>
{
    const navigate = useNavigate();
    const { dashTitle } = useData();
    const { error, setError, success, setSuccess, clearState, setUser, setUserType, setIsAuthenticated } = useAuth();
    const [isSubmitting, SetIsSubmitting] = useState<boolean>(false);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isNextStep, setIsNextStep] = useState<boolean>(false);
    const [timeRemaining, setTimeRemaining] = useState(codeTimeout);

    useEffect(() =>
    {

        //Implementing the setInterval method
        const interval = setInterval(() =>
        {
            setTimeRemaining(t => t - 1);
        }, 1000);

        //Clearing the interval
        return () => clearInterval(interval);
    })


    async function handleValidSubmit(values: Record<string, any>)
    {
        clearState();
        SetIsSubmitting(true);
        let res1: GenericResult;
        if (!isNextStep)
        {
            res1 = await sendConfirmationCode(values.phoneNumber);
            setPhoneNumber(values.phoneNumber);
            setTimeRemaining(codeTimeout);
            if (res1.success)
            {
                setIsNextStep(true);
                setSuccess(langService.Translate('A verification code has been sent to the phone number.'));
            } else
            {
                setError(res1.message ?? langService.Translate('Can not find user by the phone number'));
            }
        } else
        {

            const res2: ParticipantAuthResult = await verifyPhoneCode(phoneNumber, values.verificationCode);
            if (res2.success)
            {
                const userObj = {
                    username: res2.user?.uuid,
                    firstName: res2.user?.first_name,
                    lastName: res2.user?.last_name,
                    email: res2.user?.email_for_survey_panel,
                    userType: 'participant',
                    token: res2.authToken!,
                    groups: [],
                };
                cookies.set('authUser', userObj, { path: '/' });
                cookies.set('token', res2.authToken, { path: '/' });
                cookies.set('type', 'participant', { path: '/' });
                setUser(userObj);
                setUserType('participant');
                setIsAuthenticated(true);
                const IsOtherLang = LangServices.currentLanguage.short_code !== 'en';
                window.location.href = '/page/participant_start/' + res2.user?.uuid + (IsOtherLang ? '?L=' + LangServices.currentLanguage.short_code : '');
                // navigate('/page/participant_start/' + res2.user?.uuid);
            } else
            {
                setError(res2.message ? langService.Translate(res2.message) : langService.Translate('Can not find user by the phone number'));
            }
        }
        SetIsSubmitting(false);
    }

    const handleClearState = () =>
    {
        clearState();
    };

    const handleResendCode = async () =>
    {
        const res1 = await sendConfirmationCode(phoneNumber);
        setTimeRemaining(codeTimeout);
        if (res1.success)
        {
            setIsNextStep(true);
            setSuccess(langService.Translate('A verification code has been sent to the phone number.'));
        } else
        {
            setError(res1.message ? langService.Translate(res1.message) : langService.Translate('Can not find user by the phone number'));
        }

    }

    const titleParts = dashTitle.split('(');

    return (
        <React.Fragment>
            <div className="home-btn d-none d-sm-block">
                <Link to="/" className="text-dark">
                    <i className="fas fa-home h2"></i>
                </Link>
            </div>
            <div className="account-pages pt-sm-3">
                <Container>
                    <Row className="justify-content-center">
                        <Col md={8} lg={6} xl={5}>
                            <Card className="overflow-hidden">
                                <div className="login-header">
                                    <div className="title">
                                        <img src={logo} alt="" className="img-fluid" />
                                    </div>
                                    <img src={vector2} alt="" className="img-fluid" />
                                </div>
                                <div className="card-body pb-0">
                                    <div className="text-darkblue p-2">
                                        <h5 className="text-darkblue">{titleParts[0]}</h5>
                                        <p>{titleParts.length === 2 && '(' + titleParts[1]}</p>
                                    </div>
                                </div>
                                <CardBody className="">
                                    <div className="p-2">
                                        {error && error ? (
                                            <Alert color="danger" style={{ marginTop: '13px' }}>
                                                {error}
                                            </Alert>
                                        ) : null}
                                        {success ? (
                                            <Alert color="success" style={{ marginTop: '13px' }}>
                                                {success}
                                            </Alert>
                                        ) : null}
                                        {!isNextStep ? (
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    phoneNumber: '',
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    phoneNumber: Yup.string().required(langService.Translate('Enter Phone Number')),
                                                })}
                                                onSubmit={(values) =>
                                                {
                                                    handleValidSubmit(values);
                                                }}
                                            >
                                                {({ errors, touched }) => (
                                                    <Form className="form-horizontal">
                                                        <h3 className="text-center mb-5">{langService.Translate('Sign in using Phone Number')}</h3>
                                                        <div className="mb-3">
                                                            {langService.Translate('Enter your phone number below and we will send you a verification code')}
                                                        </div>
                                                        <div className="form-group">
                                                            <Label for="phoneNumber" className="form-label">
                                                                {langService.Translate('Phone Number')}
                                                            </Label>
                                                            <Field
                                                                name="phoneNumber"
                                                                id="phoneNumber"
                                                                type="input"
                                                                required
                                                                placeholder={langService.Translate('Enter Phone Number')}
                                                                className={
                                                                    'form-control' +
                                                                    (errors.phoneNumber && touched.phoneNumber ? ' is-invalid' : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="phoneNumber"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <Row className="form-group">
                                                            <Col className="text-right">
                                                                <button
                                                                    className="btn btn-login btn-block w-md waves-effect waves-light save-btn"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {langService.Translate('Sign In')}
                                                                    {isSubmitting && <Spinner className="spinner" />}
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        ) : (
                                            <Formik
                                                enableReinitialize={true}
                                                initialValues={{
                                                    verificationCode: '',
                                                }}
                                                validationSchema={Yup.object().shape({
                                                    verificationCode: Yup.string().required(langService.Translate('Enter Code')),
                                                })}
                                                onSubmit={(values) =>
                                                {
                                                    handleValidSubmit(values);
                                                }}
                                            >
                                                {({ errors, touched }) => (
                                                    <Form className="form-horizontal">
                                                        <h3 className="text-center mb-5">{langService.Translate('Enter Verification Code')}</h3>
                                                        <div className="mb-3">
                                                            {langService.Translate('Enter your code below to continue')}
                                                        </div>
                                                        <div className="form-group">
                                                            <Label for="verificationCode" className="form-label">
                                                                {langService.Translate('Code')}
                                                            </Label>
                                                            <Field
                                                                name="verificationCode"
                                                                type="input"
                                                                required
                                                                placeholder={langService.Translate('Enter Code')}
                                                                className={
                                                                    'form-control' +
                                                                    (errors.verificationCode && touched.verificationCode
                                                                        ? ' is-invalid'
                                                                        : '')
                                                                }
                                                            />
                                                            <ErrorMessage
                                                                name="verificationCode"
                                                                component="div"
                                                                className="invalid-feedback"
                                                            />
                                                        </div>

                                                        <Row className="form-group ">
                                                            <Col md="8">
                                                                <div className="text-left">
                                                                    {timeRemaining > 0 ? langService.Translate(`Time Remaining: ${timeRemaining} Secs`) : langService.Translate(`Didn't receive code`)}
                                                                </div>
                                                            </Col>
                                                            <Col md="4">
                                                                <div className={`text-right resend-code ${timeRemaining > 0 ? '' : 'active cursor-pointer'}`}
                                                                    onClick={timeRemaining > 0 ? undefined : handleResendCode}>
                                                                    {langService.Translate('Resend Code')}
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="form-group">
                                                            <Col className="text-right">
                                                                <button
                                                                    className="btn btn-login btn-block w-md waves-effect waves-light save-btn"
                                                                    type="submit"
                                                                    disabled={isSubmitting}
                                                                >
                                                                    {langService.Translate('Confirm')}
                                                                    {isSubmitting && <Spinner className="spinner" />}
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    </Form>
                                                )}
                                            </Formik>
                                        )}
                                    </div>
                                </CardBody>
                            </Card>
                            <div className="mt-1 text-center">
                                <p>
                                    {langService.Translate('Go back to')}{' '}
                                    <Link
                                        to="/login"
                                        className="font-weight-medium text-darkblue"
                                        onClick={handleClearState}
                                    >
                                        {langService.Translate('Sign in')}
                                    </Link>{' '}
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default MobileLoginPage;
