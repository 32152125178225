import React, { useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { UncontrolledTooltip } from 'reactstrap';
import { LangServices } from '@lainaedge/platformshared';

import { AxiosError } from 'axios';
import { ReactComponent as BarsIcon } from 'Common/assets/images/icons/burger.svg';
import { ReactComponent as GearIcon } from 'Common/assets/images/icons/gear.svg';
// Import menuDropdown
// import LanguageDropdown from 'Common/components/LanguageDropdown';
import ProfileMenu from 'Common/components/ProfileMenu';
import SearchResultBox from 'Common/components/SearchResultBox';
import { myProjectName, myServerEnv } from 'Common/constants';
import { useData } from 'Common/context/DataContext';
import { deleteBookmark, searchParticipant } from 'Common/services';
import debounce from 'debounce';

const langService = LangServices.instance();

const Header = () =>
{
  const {
    leftSideBarType,
    setLeftSideBarType,
    showGearModal,
    setShowGearModal,
    bookmarked,
    setBookmark,
    setShowBookmarkModal,
  } = useData();
  const location = useLocation();
  const [options, setOptions] = useState([]);
  const [showSearchResultBox, setShowSearchResultBox] = useState(false);
  const inputElement = useRef<any>();

  // const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const tToggle = () =>
  {
    if (leftSideBarType === 'default')
    {
      setLeftSideBarType('menu-closed');
    } else if (['condensed', 'menu-opened', 'menu-closed'].includes(leftSideBarType))
    {
      setLeftSideBarType('default');
    }
  };

  const toggleLogicValuesModal = () =>
  {
    setShowGearModal(!showGearModal);
  };

  const toggleBookmark = () =>
  {
    if (!bookmarked)
    {
      setShowBookmarkModal(true);
      return;
    } else
    {
      deleteBookmark(location.pathname, myProjectName.toLowerCase());
    }
    setBookmark(!bookmarked);
  };

  const handleChange = (e: any) =>
  {
    if (e.target.value.length < 2)
    {
      return;
    }
    getSuggestionsData(e.target.value);
  };

  const getSuggestionsData = async (queryStr: string) =>
  {
    try
    {
      if (!queryStr)
      {
        setOptions([]);
        return;
      }
      const optionsData = await searchParticipant(queryStr);

      if (optionsData)
      {
        if (optionsData.data?.length)
        {
          setOptions(optionsData.data);
        } else
        {
          setOptions([]);
        }
        setShowSearchResultBox(true);
      }
    } catch (err: any | AxiosError)
    {
      console.log('SEARCH PARTICIPANT error:', err.response.data);
    }
  };

  const clearInput = () =>
  {
    inputElement.current.value = '';
  };

  const searchDelayed = debounce(handleChange, 500);

  return (
    <React.Fragment>
      <header id="page-topbar" data-testid="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <button
              type="button"
              onClick={() =>
              {
                tToggle();
              }}
              className="ml-3 btn btn-sm font-size-24 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <BarsIcon />
            </button>
            <button
              type="button"
              onClick={() =>
              {
                toggleLogicValuesModal();
              }}
              className="ml-1 btn btn-sm font-size-24 header-item waves-effect"
              id="gear-btn"
            >
              <GearIcon />
            </button>
            <button
              type="button"
              onClick={() =>
              {
                toggleBookmark();
              }}
              className="ml-1 btn btn-sm font-size-24 header-item waves-effect"
              id="bookmark-btn"
            >
              <div className={`${bookmarked ? '' : 'hide'}`} id="tooltip-bookmarked">
                <i className="fa-solid fa-bookmark"></i>
              </div>
              <UncontrolledTooltip placement="bottom" target={`tooltip-bookmarked`}>
                {langService.Translate('Already bookmarked')}
              </UncontrolledTooltip>
              <div className={`${bookmarked ? 'hide' : ''}`} id="tooltip-bookmark">
                <i className="fa-regular fa-bookmark"></i>
              </div>
              <UncontrolledTooltip placement="bottom" target={`tooltip-bookmark`}>
                {langService.Translate('Bookmark this page')}
              </UncontrolledTooltip>
            </button>
            {myServerEnv == 'uat' && (
              <div className="p-2 d-flex justify-content-center align-items-center text-white cursor-pointer">
                UAT
              </div>
            )}
          </div>
          <div className="d-flex">
            <form className="app-search">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  id="search-input"
                  placeholder={'Search...'}
                  onChange={searchDelayed}
                  ref={inputElement}
                />
                <span className="bx bx-search-alt"></span>
              </div>
              {showSearchResultBox && (
                <SearchResultBox
                  options={options}
                  clearInput={clearInput}
                  setShowSearchResultBox={setShowSearchResultBox}
                />
              )}
            </form>
            {/* <LanguageDropdown /> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

export default Header;
