import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Alert, Label, Spinner } from 'reactstrap';

import { DataServices, LangServices, TAvailableLanguage } from '@lainaedge/platformshared';
import { ValidateParticipantResult } from '@lainaedge/platformshared/src/types/ValidateParticipantResult';
import { myProjectName } from 'Common/constants';
import Layout from 'Common/layout/StudyNonAuthLayout';
import { IPariticipantStudyFormFields } from 'Common/types';
import { ErrorMessage, Field, Form, Formik } from 'formik';
import * as Yup from 'yup';

const dataService = DataServices.instance();
const langService = LangServices.instance();

/**
 * Study component.
 *
 * @remarks
 * Page where participant can log in
 *
 * @component Study
 * @category Page
 */
const Study = (): JSX.Element =>
{
  const navigate = useNavigate();
  const [isSubmitting, SetIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [supportMultiLang, setSupportMultiLang] = useState(false);
  const [languages, setLanguages] = useState<TAvailableLanguage[]>([]);
  const [selectedLang, setSelectedLanguage] = useState('en-US');

  const mounted = useRef(false);
  useEffect(() =>
  {
    mounted.current = true;
    return () =>
    {
      mounted.current = false;
    };
  });

  useEffect(() =>
  {
    async function checkLanguageSupport()
    {
      const availableLanguages: TAvailableLanguage[] = await langService.GetAvailableLanguages();

      if (availableLanguages.length > 1)
      {
        console.log(availableLanguages);
        setSupportMultiLang(true);
        setLanguages(availableLanguages);
      }

      LangServices.evLanguageChange.subscribe((lang: TAvailableLanguage) =>
      {
        setSelectedLanguage(lang.code);
      });
    }

    checkLanguageSupport();
  }, []);

  async function handleValidSubmit(values: IPariticipantStudyFormFields)
  {
    SetIsSubmitting(true);
    const result: ValidateParticipantResult = await dataService.validateParticipantHubServer(
      values.participantId,
    );
    if (result.success)
    {
      if (result.passwordStatus === 'needed')
      {
        if (result.trialName?.toLowerCase() !== myProjectName.toLowerCase())
        {
          window.location.href =
            'https://' + result.trialAddress + '/study/setPassword/' + values.participantId;
        } else
        {
          navigate('/study/setPassword/' + values.participantId);
        }
      } else
      {
        if (result.trialName?.toLowerCase() !== myProjectName.toLowerCase())
        {
          window.location.href =
            'https://' + result.trialAddress + '/study/enterPassword/' + values.participantId;
        } else
        {
          navigate('/study/enterPassword/' + values.participantId);
        }
      }
    } else
    {
      const err =
        result.errorMessage == 'Can not find the participant'
          ? langService.Translate('We don’t recognize this participant ID. Please try again.')
          : langService.Translate(result.errorMessage!);
      setErrorMessage(err!);
    }

    // component is unmounted
    if (!mounted.current) return;
    SetIsSubmitting(false);
  }

  const handleSelectLanguage = (e: React.ChangeEvent<HTMLSelectElement>) =>
  {
    const lang = e.target.value !== '' ? e.target.value : 'en-US';
    langService.setLanguage(lang);
    setSelectedLanguage(lang);
  }

  return (
    <Layout>
      <Formik
        enableReinitialize={true}
        initialValues={{
          participantId: '',
        }}
        validationSchema={Yup.object().shape({
          participantId: Yup.string().required(langService.Translate('Enter Participant ID')),
        })}
        onSubmit={(values) =>
        {
          handleValidSubmit(values);
        }}
      >
        {({ errors, touched }) => (
          <Form className="form-horizontal">
            {errorMessage && errorMessage ? <Alert color="danger">{errorMessage}</Alert> : null}
            <div className="mb-3">
              <Label for="participantId" className="form-label">
                {langService.Translate("Please enter the survey ID or participant ID that has been assigned to you")}
              </Label>
              <Field
                name="participantId"
                id="participantId"
                type="input"
                className={
                  'form-control' +
                  (errors.participantId && touched.participantId ? ' is-invalid' : '')
                }
              />
              <ErrorMessage name="participantId" component="div" className="invalid-feedback" />
            </div>

            <div className="mt-3 d-grid">
              <button
                className="btn btn-login btn-block waves-effect waves-light save-btn"
                type="submit"
                disabled={isSubmitting}
              >
                {langService.Translate("Sign In")}
                {isSubmitting && <Spinner className="spinner" />}
              </button>
            </div>

            {supportMultiLang && <div className="mt-3">
              <select
                className={'form-control drop-down'}
                name={'language-options'}
                onChange={handleSelectLanguage}
                value={selectedLang}
              >
                <option value="">{langService.Translate('Select')}</option>
                {languages.map((lang: TAvailableLanguage) => (
                  <option key={lang.short_code} value={lang.code}>
                    {lang.name}
                  </option>
                ))}
              </select>
            </div>}

            <div className="mt-3">
              <Link
                className="btn btn-light-login btn-block waves-effect waves-light save-btn"
                to="/login"
              >
                {langService.Translate("I have a coordinator username")}
              </Link>
            </div>

            <div className="mt-3">
              <Link
                to="/mobile-login"
                className="btn btn-light-login btn-block waves-effect waves-light save-btn"
              >
                {langService.Translate("Sign in using your phone number")}
              </Link>
            </div>
          </Form>
        )}
      </Formik>
    </Layout>
  );
};

export default Study;
